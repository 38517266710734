@use "sass:math";

$sizes: 6;


@mixin layered-shadow-classes {
    @for $i from 1 through $sizes {

        .lshadow-#{$i} {
            box-shadow: create-shadow-layers($i);
        }

    }
}

@function create-shadow-layers($layers){

    /* $val: 0 1px 1px rgba(0,0,0,0.75); */
    $val: null !default;

    @for $s from 1 through $layers{
        $k: pow(2, ($s - 1));
        $val: $val, 0 #{math.div($k, 4)}rem #{math.div($k, 2)}rem rgba(0,0,0,0.15); /* bootstrap vars */
    }
    @return $val;
}

@function pow($base, $exponents){
    $raised: 1;
    @for $i from 0 through $exponents {
        $raised: $raised * $base;
    }
    @return $raised;
}

@include layered-shadow-classes;
