/* BS4 Grid XXL breakpoint */

:root {
    --breakpoint-xxl: 1400px;
}


.container-xxl {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}


@media (min-width: 1400px) {
    .container-xxl {
        max-width: 1320px;
    }
}


.col-xxl,
.col-xxl-auto,
.col-xxl-12,
.col-xxl-11,
.col-xxl-10,
.col-xxl-9,
.col-xxl-8,
.col-xxl-7,
.col-xxl-6,
.col-xxl-5,
.col-xxl-4,
.col-xxl-3,
.col-xxl-2,
.col-xxl-1 {
    position: relative;
    width: 100%;
    // padding-right: 15px;
    // padding-left: 15px;
}


@media (min-width: 1400px) {
    .col-xxl {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
    }

    .row-cols-xxl-1>* {
        flex: 0 0 100%;
        max-width: 100%;
    }

    .row-cols-xxl-2>* {
        flex: 0 0 50%;
        max-width: 50%;
    }

    .row-cols-xxl-3>* {
        flex: 0 0 33.3333333333%;
        max-width: 33.3333333333%;
    }

    .row-cols-xxl-4>* {
        flex: 0 0 25%;
        max-width: 25%;
    }

    .row-cols-xxl-5>* {
        flex: 0 0 20%;
        max-width: 20%;
    }

    .row-cols-xxl-6>* {
        flex: 0 0 16.6666666667%;
        max-width: 16.6666666667%;
    }

    .col-xxl-auto {
        flex: 0 0 auto;
        width: auto;
        max-width: 100%;
    }

    .col-xxl-1 {
        flex: 0 0 8.33333333%;
        max-width: 8.33333333%;
    }

    .col-xxl-2 {
        flex: 0 0 16.66666667%;
        max-width: 16.66666667%;
    }

    .col-xxl-3 {
        flex: 0 0 25%;
        max-width: 25%;
    }

    .col-xxl-4 {
        flex: 0 0 33.33333333%;
        max-width: 33.33333333%;
    }

    .col-xxl-5 {
        flex: 0 0 41.66666667%;
        max-width: 41.66666667%;
    }

    .col-xxl-6 {
        flex: 0 0 50%;
        max-width: 50%;
    }

    .col-xxl-7 {
        flex: 0 0 58.33333333%;
        max-width: 58.33333333%;
    }

    .col-xxl-8 {
        flex: 0 0 66.66666667%;
        max-width: 66.66666667%;
    }

    .col-xxl-9 {
        flex: 0 0 75%;
        max-width: 75%;
    }

    .col-xxl-10 {
        flex: 0 0 83.33333333%;
        max-width: 83.33333333%;
    }

    .col-xxl-11 {
        flex: 0 0 91.66666667%;
        max-width: 91.66666667%;
    }

    .col-xxl-12 {
        flex: 0 0 100%;
        max-width: 100%;
    }

    .order-xxl-first {
        order: -1;
    }

    .order-xxl-last {
        order: 13;
    }

    .order-xxl-0 {
        order: 0;
    }

    .order-xxl-1 {
        order: 1;
    }

    .order-xxl-2 {
        order: 2;
    }

    .order-xxl-3 {
        order: 3;
    }

    .order-xxl-4 {
        order: 4;
    }

    .order-xxl-5 {
        order: 5;
    }

    .order-xxl-6 {
        order: 6;
    }

    .order-xxl-7 {
        order: 7;
    }

    .order-xxl-8 {
        order: 8;
    }

    .order-xxl-9 {
        order: 9;
    }

    .order-xxl-10 {
        order: 10;
    }

    .order-xxl-11 {
        order: 11;
    }

    .order-xxl-12 {
        order: 12;
    }

    .offset-xxl-0 {
        margin-left: 0;
    }

    .offset-xxl-1 {
        margin-left: 8.33333333%;
    }

    .offset-xxl-2 {
        margin-left: 16.66666667%;
    }

    .offset-xxl-3 {
        margin-left: 25%;
    }

    .offset-xxl-4 {
        margin-left: 33.33333333%;
    }

    .offset-xxl-5 {
        margin-left: 41.66666667%;
    }

    .offset-xxl-6 {
        margin-left: 50%;
    }

    .offset-xxl-7 {
        margin-left: 58.33333333%;
    }

    .offset-xxl-8 {
        margin-left: 66.66666667%;
    }

    .offset-xxl-9 {
        margin-left: 75%;
    }

    .offset-xxl-10 {
        margin-left: 83.33333333%;
    }

    .offset-xxl-11 {
        margin-left: 91.66666667%;
    }
}



@media (min-width: 1400px) {
    .dropdown-menu-xxl-left {
        right: auto;
        left: 0;
    }

    .dropdown-menu-xxl-right {
        right: 0;
        left: auto;
    }
}


.navbar .container-xxl {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
}


.navbar-expand>.container-xxl {
    padding-right: 0;
    padding-left: 0;
}


.navbar-expand>.container-xxl {
    flex-wrap: nowrap;
}


@media (min-width: 1400px) {
    .list-group-horizontal-xxl {
        flex-direction: row;
    }

    .list-group-horizontal-xxl>.list-group-item:first-child {
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0;
    }

    .list-group-horizontal-xxl>.list-group-item:last-child {
        border-top-right-radius: 0.25rem;
        border-bottom-left-radius: 0;
    }

    .list-group-horizontal-xxl>.list-group-item.active {
        margin-top: 0;
    }

    .list-group-horizontal-xxl>.list-group-item+.list-group-item {
        border-top-width: 1px;
        border-left-width: 0;
    }

    .list-group-horizontal-xxl>.list-group-item+.list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px;
    }
}


@media (min-width: 1400px) {
    .d-xxl-none {
        display: none !important;
    }

    .d-xxl-inline {
        display: inline !important;
    }

    .d-xxl-inline-block {
        display: inline-block !important;
    }

    .d-xxl-block {
        display: block !important;
    }

    .d-xxl-table {
        display: table !important;
    }

    .d-xxl-table-row {
        display: table-row !important;
    }

    .d-xxl-table-cell {
        display: table-cell !important;
    }

    .d-xxl-flex {
        display: flex !important;
    }

    .d-xxl-inline-flex {
        display: inline-flex !important;
    }

    .d-xxl-list-item {
        display: list-item !important;
    }
}


@media (min-width: 1400px) {
    .flex-xxl-row {
        flex-direction: row !important;
    }

    .flex-xxl-column {
        flex-direction: column !important;
    }

    .flex-xxl-row-reverse {
        flex-direction: row-reverse !important;
    }

    .flex-xxl-column-reverse {
        flex-direction: column-reverse !important;
    }

    .flex-xxl-wrap {
        flex-wrap: wrap !important;
    }

    .flex-xxl-nowrap {
        flex-wrap: nowrap !important;
    }

    .flex-xxl-wrap-reverse {
        flex-wrap: wrap-reverse !important;
    }

    .flex-xxl-fill {
        flex: 1 1 auto !important;
    }

    .flex-xxl-grow-0 {
        flex-grow: 0 !important;
    }

    .flex-xxl-grow-1 {
        flex-grow: 1 !important;
    }

    .flex-xxl-shrink-0 {
        flex-shrink: 0 !important;
    }

    .flex-xxl-shrink-1 {
        flex-shrink: 1 !important;
    }

    .justify-content-xxl-start {
        justify-content: flex-start !important;
    }

    .justify-content-xxl-end {
        justify-content: flex-end !important;
    }

    .justify-content-xxl-center {
        justify-content: center !important;
    }

    .justify-content-xxl-between {
        justify-content: space-between !important;
    }

    .justify-content-xxl-around {
        justify-content: space-around !important;
    }

    .align-items-xxl-start {
        align-items: flex-start !important;
    }

    .align-items-xxl-end {
        align-items: flex-end !important;
    }

    .align-items-xxl-center {
        align-items: center !important;
    }

    .align-items-xxl-baseline {
        align-items: baseline !important;
    }

    .align-items-xxl-stretch {
        align-items: stretch !important;
    }

    .align-content-xxl-start {
        align-content: flex-start !important;
    }

    .align-content-xxl-end {
        align-content: flex-end !important;
    }

    .align-content-xxl-center {
        align-content: center !important;
    }

    .align-content-xxl-between {
        align-content: space-between !important;
    }

    .align-content-xxl-around {
        align-content: space-around !important;
    }

    .align-content-xxl-stretch {
        align-content: stretch !important;
    }

    .align-self-xxl-auto {
        align-self: auto !important;
    }

    .align-self-xxl-start {
        align-self: flex-start !important;
    }

    .align-self-xxl-end {
        align-self: flex-end !important;
    }

    .align-self-xxl-center {
        align-self: center !important;
    }

    .align-self-xxl-baseline {
        align-self: baseline !important;
    }

    .align-self-xxl-stretch {
        align-self: stretch !important;
    }
}

@media (min-width: 1400px) {
    .float-xxl-left {
        float: left !important;
    }

    .float-xxl-right {
        float: right !important;
    }

    .float-xxl-none {
        float: none !important;
    }
}


@media (min-width: 1400px) {
    .m-xxl-0 {
        margin: 0 !important;
    }

    .mt-xxl-0,
    .my-xxl-0 {
        margin-top: 0 !important;
    }

    .mr-xxl-0,
    .mx-xxl-0 {
        margin-right: 0 !important;
    }

    .mb-xxl-0,
    .my-xxl-0 {
        margin-bottom: 0 !important;
    }

    .ml-xxl-0,
    .mx-xxl-0 {
        margin-left: 0 !important;
    }

    .m-xxl-1 {
        margin: 0.25rem !important;
    }

    .mt-xxl-1,
    .my-xxl-1 {
        margin-top: 0.25rem !important;
    }

    .mr-xxl-1,
    .mx-xxl-1 {
        margin-right: 0.25rem !important;
    }

    .mb-xxl-1,
    .my-xxl-1 {
        margin-bottom: 0.25rem !important;
    }

    .ml-xxl-1,
    .mx-xxl-1 {
        margin-left: 0.25rem !important;
    }

    .m-xxl-2 {
        margin: 0.5rem !important;
    }

    .mt-xxl-2,
    .my-xxl-2 {
        margin-top: 0.5rem !important;
    }

    .mr-xxl-2,
    .mx-xxl-2 {
        margin-right: 0.5rem !important;
    }

    .mb-xxl-2,
    .my-xxl-2 {
        margin-bottom: 0.5rem !important;
    }

    .ml-xxl-2,
    .mx-xxl-2 {
        margin-left: 0.5rem !important;
    }

    .m-xxl-3 {
        margin: 1rem !important;
    }

    .mt-xxl-3,
    .my-xxl-3 {
        margin-top: 1rem !important;
    }

    .mr-xxl-3,
    .mx-xxl-3 {
        margin-right: 1rem !important;
    }

    .mb-xxl-3,
    .my-xxl-3 {
        margin-bottom: 1rem !important;
    }

    .ml-xxl-3,
    .mx-xxl-3 {
        margin-left: 1rem !important;
    }

    .m-xxl-4 {
        margin: 1.5rem !important;
    }

    .mt-xxl-4,
    .my-xxl-4 {
        margin-top: 1.5rem !important;
    }

    .mr-xxl-4,
    .mx-xxl-4 {
        margin-right: 1.5rem !important;
    }

    .mb-xxl-4,
    .my-xxl-4 {
        margin-bottom: 1.5rem !important;
    }

    .ml-xxl-4,
    .mx-xxl-4 {
        margin-left: 1.5rem !important;
    }

    .m-xxl-5 {
        margin: 3rem !important;
    }

    .mt-xxl-5,
    .my-xxl-5 {
        margin-top: 3rem !important;
    }

    .mr-xxl-5,
    .mx-xxl-5 {
        margin-right: 3rem !important;
    }

    .mb-xxl-5,
    .my-xxl-5 {
        margin-bottom: 3rem !important;
    }

    .ml-xxl-5,
    .mx-xxl-5 {
        margin-left: 3rem !important;
    }

    .p-xxl-0 {
        padding: 0 !important;
    }

    .pt-xxl-0,
    .py-xxl-0 {
        padding-top: 0 !important;
    }

    .pr-xxl-0,
    .px-xxl-0 {
        padding-right: 0 !important;
    }

    .pb-xxl-0,
    .py-xxl-0 {
        padding-bottom: 0 !important;
    }

    .pl-xxl-0,
    .px-xxl-0 {
        padding-left: 0 !important;
    }

    .p-xxl-1 {
        padding: 0.25rem !important;
    }

    .pt-xxl-1,
    .py-xxl-1 {
        padding-top: 0.25rem !important;
    }

    .pr-xxl-1,
    .px-xxl-1 {
        padding-right: 0.25rem !important;
    }

    .pb-xxl-1,
    .py-xxl-1 {
        padding-bottom: 0.25rem !important;
    }

    .pl-xxl-1,
    .px-xxl-1 {
        padding-left: 0.25rem !important;
    }

    .p-xxl-2 {
        padding: 0.5rem !important;
    }

    .pt-xxl-2,
    .py-xxl-2 {
        padding-top: 0.5rem !important;
    }

    .pr-xxl-2,
    .px-xxl-2 {
        padding-right: 0.5rem !important;
    }

    .pb-xxl-2,
    .py-xxl-2 {
        padding-bottom: 0.5rem !important;
    }

    .pl-xxl-2,
    .px-xxl-2 {
        padding-left: 0.5rem !important;
    }

    .p-xxl-3 {
        padding: 1rem !important;
    }

    .pt-xxl-3,
    .py-xxl-3 {
        padding-top: 1rem !important;
    }

    .pr-xxl-3,
    .px-xxl-3 {
        padding-right: 1rem !important;
    }

    .pb-xxl-3,
    .py-xxl-3 {
        padding-bottom: 1rem !important;
    }

    .pl-xxl-3,
    .px-xxl-3 {
        padding-left: 1rem !important;
    }

    .p-xxl-4 {
        padding: 1.5rem !important;
    }

    .pt-xxl-4,
    .py-xxl-4 {
        padding-top: 1.5rem !important;
    }

    .pr-xxl-4,
    .px-xxl-4 {
        padding-right: 1.5rem !important;
    }

    .pb-xxl-4,
    .py-xxl-4 {
        padding-bottom: 1.5rem !important;
    }

    .pl-xxl-4,
    .px-xxl-4 {
        padding-left: 1.5rem !important;
    }

    .p-xxl-5 {
        padding: 3rem !important;
    }

    .pt-xxl-5,
    .py-xxl-5 {
        padding-top: 3rem !important;
    }

    .pr-xxl-5,
    .px-xxl-5 {
        padding-right: 3rem !important;
    }

    .pb-xxl-5,
    .py-xxl-5 {
        padding-bottom: 3rem !important;
    }

    .pl-xxl-5,
    .px-xxl-5 {
        padding-left: 3rem !important;
    }

    .m-xxl-n1 {
        margin: -0.25rem !important;
    }

    .mt-xxl-n1,
    .my-xxl-n1 {
        margin-top: -0.25rem !important;
    }

    .mr-xxl-n1,
    .mx-xxl-n1 {
        margin-right: -0.25rem !important;
    }

    .mb-xxl-n1,
    .my-xxl-n1 {
        margin-bottom: -0.25rem !important;
    }

    .ml-xxl-n1,
    .mx-xxl-n1 {
        margin-left: -0.25rem !important;
    }

    .m-xxl-n2 {
        margin: -0.5rem !important;
    }

    .mt-xxl-n2,
    .my-xxl-n2 {
        margin-top: -0.5rem !important;
    }

    .mr-xxl-n2,
    .mx-xxl-n2 {
        margin-right: -0.5rem !important;
    }

    .mb-xxl-n2,
    .my-xxl-n2 {
        margin-bottom: -0.5rem !important;
    }

    .ml-xxl-n2,
    .mx-xxl-n2 {
        margin-left: -0.5rem !important;
    }

    .m-xxl-n3 {
        margin: -1rem !important;
    }

    .mt-xxl-n3,
    .my-xxl-n3 {
        margin-top: -1rem !important;
    }

    .mr-xxl-n3,
    .mx-xxl-n3 {
        margin-right: -1rem !important;
    }

    .mb-xxl-n3,
    .my-xxl-n3 {
        margin-bottom: -1rem !important;
    }

    .ml-xxl-n3,
    .mx-xxl-n3 {
        margin-left: -1rem !important;
    }

    .m-xxl-n4 {
        margin: -1.5rem !important;
    }

    .mt-xxl-n4,
    .my-xxl-n4 {
        margin-top: -1.5rem !important;
    }

    .mr-xxl-n4,
    .mx-xxl-n4 {
        margin-right: -1.5rem !important;
    }

    .mb-xxl-n4,
    .my-xxl-n4 {
        margin-bottom: -1.5rem !important;
    }

    .ml-xxl-n4,
    .mx-xxl-n4 {
        margin-left: -1.5rem !important;
    }

    .m-xxl-n5 {
        margin: -3rem !important;
    }

    .mt-xxl-n5,
    .my-xxl-n5 {
        margin-top: -3rem !important;
    }

    .mr-xxl-n5,
    .mx-xxl-n5 {
        margin-right: -3rem !important;
    }

    .mb-xxl-n5,
    .my-xxl-n5 {
        margin-bottom: -3rem !important;
    }

    .ml-xxl-n5,
    .mx-xxl-n5 {
        margin-left: -3rem !important;
    }

    .m-xxl-auto {
        margin: auto !important;
    }

    .mt-xxl-auto,
    .my-xxl-auto {
        margin-top: auto !important;
    }

    .mr-xxl-auto,
    .mx-xxl-auto {
        margin-right: auto !important;
    }

    .mb-xxl-auto,
    .my-xxl-auto {
        margin-bottom: auto !important;
    }

    .ml-xxl-auto,
    .mx-xxl-auto {
        margin-left: auto !important;
    }
}


@media (min-width: 1400px) {
    .text-xxl-left {
        text-align: left !important;
    }

    .text-xxl-right {
        text-align: right !important;
    }

    .text-xxl-center {
        text-align: center !important;
    }
}