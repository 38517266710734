@import '../scss/variables.scss';
/* Tourmie custom colors for Bootstrap 4 */


.timeline {
  margin: 0 0 45px;
  padding: 0;
  position: relative;
}

.timeline::before {
  border-radius: 0.25rem;
  background: #dee2e6;
  bottom: 0;
  content: '';
  left: 31px;
  margin: 0;
  position: absolute;
  top: 0;
  width: 4px;
}

.timeline>div>div {
  margin-bottom: 20px;
  /* margin-right: 10px; */
  position: relative;
}

.timeline>div>div::before,
.timeline>div>div::after {
  content: "";
  display: table;
}

.timeline>div>div>.timeline-item {
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.125), 0 1px 3px rgba(0, 0, 0, 0.2);
  border-radius: 0.25rem;
  background: #ffffff;
  color: #495057;
  margin-left: 60px;
  margin-right: 15px;
  margin-top: 0;
  padding: 0;
  position: relative;
}

.timeline>div>div>.timeline-item>.time {
  color: #999;
  float: right;
  font-size: 12px;
  padding: 10px;
}

.timeline>div>div>.timeline-item>.timeline-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  color: #495057;
  font-size: 16px;
  line-height: 1.1;
  margin: 0;
  padding: 10px;
}

.timeline>div>div>.timeline-item>.timeline-header>a {
  font-weight: 600;
}

.timeline>div>div>.timeline-item>.timeline-body,
.timeline>div>div>.timeline-item>.timeline-footer {
  /* padding: 10px; */
  padding: 10px 12px;
}

.timeline>div>div>.timeline-item>.timeline-body>img {
  margin: 10px;
}

.timeline>div>div>.timeline-item>.timeline-body>dl,
.timeline>div>div>.timeline-item>.timeline-body ol,
.timeline>div>div>.timeline-item>.timeline-body ul {
  margin: 0;
}

.timeline>div>div>.timeline-item>.timeline-footer>a {
  color: #ffffff;
}

.timeline>div>div>.fa,
.timeline>div>div>.fas,
.timeline>div>div>.far,
.timeline>div>div>.fab,
.timeline>div>div>.glyphicon,
.timeline>div>div>.ion {
  background: #adb5bd;
  border-radius: 50%;
  font-size: 15px;
  height: 30px;
  left: 18px;
  line-height: 30px;
  position: absolute;
  text-align: center;
  top: 0;
  width: 30px;
}

.timeline>div>.time-label>span {
  border-radius: 4px;
  background-color: #ffffff;
  display: inline-block;
  font-weight: 600;
  padding: 5px 10px;
}

.timeline-inverse>div>div>.timeline-item {
  box-shadow: none;
  background: #f8f9fa;
  border: 1px solid #dee2e6;
}

.timeline-inverse>div>div>.timeline-item>.timeline-header {
  border-bottom-color: #dee2e6;
}

.timeline>div>div>.fas,
.timeline>div>div>.far {
  background-color: var(--tourmie-secondary) !important;
}