// /* If new colors added here add them also in color-fixes.scss, and :root{} of adminlte.scss */

$tourmie-secondary: #27a9e0 !default;
$tourmie-lightblue: #d2edf9 !default;
$tourmie-darkblue: #051320 !default;
$tourmie-darkblue-15: #0a2742 !default;
$tourmie-darkblue-20: #0e3458 !default;
$tourmie-darkblue-25: #11416e !default;
$tourmie-darkblue-30: #154e84 !default;
$tourmie-primary: #faaf40 !default;
$tourmie-primary-90: #feeacd !default;
$tourmie-primary-85: #fde0b4 !default;
$tourmie-primary-80: #fcd69c !default;
$tourmie-primary-75: #fccb83 !default;
$tourmie-primary-70: #fbc16a !default;
$tourmie-primary-65: #fbb751 !default;
$tourmie-primary-60: #faac38 !default;
$tourmie-primary-55: #f9a21f !default;
$tourmie-primary-50: #f99806 !default;
$tourmie-dark-blue-footer: #051320 !default;
$tourmie-darkblue-2: #5f727f !default;

$spacer: 1rem !default;
$spacers: (
  ) !default;
/* stylelint-disable-next-line scss/dollar-variable-default */
$spacers: map-merge((0: 0,
      1: ($spacer * .25),
      2: ($spacer * .5),
      3: $spacer,
      4: ($spacer * 1.5),
      5: ($spacer * 3),
      6: ($spacer * 4), //64px
      7: ($spacer * 5), //80px
      8: ($spacer * 6.25), //100px
      9: ($spacer * 7.5), //120px
      10: ($spacer * 9.375) //150px
    ),
    $spacers
);