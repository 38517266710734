.custom-clustericon {
  background: var(--cluster-color);
  color: #fff;
  border-radius: 100%;
  font-weight: 500;
  font-size: 16px;
  display: flex;
  align-items: center;
}

.custom-clustericon::before,
.custom-clustericon::after {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  background: var(--cluster-color);
  opacity: 0.2;
  border-radius: 100%;
}

.custom-clustericon::before {
  padding: 22px;
}

.custom-clustericon::after {
  padding: 28px;
}


/* Cluster icon 1 */
.custom-clustericon-1 {
  --cluster-color: #63c1e9; /* #8fd2ef; */
}
.custom-clustericon-1:before {
  padding: 22px;
}



/* Cluster icon 2 */
.custom-clustericon-2 {
  --cluster-color: #63c1e9;
}

.custom-clustericon-2:before {
  padding: 24px;
}



/* Cluster icon 3 */
.custom-clustericon-3 {
  --cluster-color: #36afe2;
}

.custom-clustericon-3:before {
  padding: 28px;
}

.custom-clustericon-3:after {
  padding: 36px;
}
