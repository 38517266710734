.btn-tool {
  background: transparent;
  color: #adb5bd;
  font-size: 0.875rem;
  margin: -0.75rem 0;
  padding: 0.25rem 0.5rem;
}

.btn-tool:hover {
  color: #495057 !important;
}


.btn-default {
  background-color: #f8f9fa;
  border-color: #ddd;
  color: #333;
}


$tourmie-color-range: 100;

@mixin tourmie-secondary-light {
  @for $i from 1 through $tourmie-color-range {
    $percent: $i * 1%;

    // TODO: fix me
    // .text-tourmie-secondary-light-#{$i} {
    //   color: lighten(var(--tourmie-secondary), $percent) !important;
    // }

    // .bg-tourmie-secondary-light-#{$i} {
    //   background-color: lighten(var(--tourmie-secondary), $percent) !important;
    // }

  }
}

@include tourmie-secondary-light;

@mixin tourmie-primary-light {
  @for $i from 1 through $tourmie-color-range {
    $percent: $i * 1%;

    // TODO: fix me
    // .text-tourmie-primary-light-#{$i} {
    //   color: lighten(var(--tourmie-primary), $percent) !important;
    // }

    // .bg-tourmie-primary-light-#{$i} {
    //   background-color: lighten(var(--tourmie-primary), $percent) !important;
    // }

  }
}

@include tourmie-primary-light;

.bg-infobox-gray {
  background-color: #e9e9e9 !important;
}



.bg-tourmie-dark-blue {
  background-color: var(--tourmie-dark-blue-footer) !important;
}

/* FIXES FOR COLOR: TOURMIE-SECONDARY */

.bg-tourmie-secondary .btn-tool,
.bg-gradient-tourmie-secondary .btn-tool,
.card-tourmie-secondary:not(.card-outline) .btn-tool {
  color: rgba(255, 255, 255, 0.8);
}

.bg-tourmie-secondary .btn-tool:hover,
.bg-gradient-tourmie-secondary .btn-tool:hover,
.card-tourmie-secondary:not(.card-outline) .btn-tool:hover {
  color: #ffffff;
}


// TODO: fix me
// .icheck-tourmie-secondary>input:first-child:not(:checked):not(:disabled):hover+label::before,
// .icheck-tourmie-secondary>input:first-child:not(:checked):not(:disabled):hover+input[type="hidden"]+label::before {
//   border-color: darken(var(--tourmie-secondary), 15%);
// }

// TODO: fix me
// .icheck-tourmie-secondary>input:first-child:checked+label::before,
// .icheck-tourmie-secondary>input:first-child:checked+input[type="hidden"]+label::before {
//   background-color: darken(var(--tourmie-secondary), 5%);
//   border-color: darken(var(--tourmie-secondary), 15%);
// }

.card-tourmie-secondary.card-outline {
  border-top: 3px solid var(--tourmie-secondary);
}

.bg-tourmie-secondary {
  color: $white;
}

.callout.callout-tourmie-secondary {
  border-left-color: var(--tourmie-secondary);
}

.navbar-tourmie-secondary {
  background-color: var(--tourmie-secondary) !important;
}

a.brand-link.brand-tourmie-secondary {
  background-color: var(--tourmie-secondary) !important;

  img.logo-xl {
    max-width: 120px;
  }

}

.progress-bar-tourmie-secondary {
  background-color: var(--tourmie-secondary) !important;
}

/* FIXES FOR COLOR: TOURMIE-PRIMARY */

// TODO: fix me
// .icheck-tourmie-primary>input:first-child:not(:checked):not(:disabled):hover+label::before,
// .icheck-tourmie-primary>input:first-child:not(:checked):not(:disabled):hover+input[type="hidden"]+label::before {
//   border-color: darken(var(--tourmie-primary), 15%);
// }

// TODO: fix me
// .icheck-tourmie-primary>input:first-child:checked+label::before,
// .icheck-tourmie-primary>input:first-child:checked+input[type="hidden"]+label::before {
//   background-color: darken(var(--tourmie-primary), 5%);
//   border-color: darken(var(--tourmie-primary), 15%);
// }


.card-tourmie-primary.card-outline {
  border-top: 3px solid var(--tourmie-primary);
}

.callout.callout-tourmie-primary {
  border-left-color: var(--tourmie-primary);
}

.navbar-tourmie-primary {
  background-color: var(--tourmie-primary) !important;
}

a.brand-link.brand-tourmie-primary {
  background-color: var(--tourmie-primary) !important;

  img.logo-xl {
    max-width: 120px;
  }
}

.progress-bar-tourmie-primary {
  background-color: var(--tourmie-primary) !important;
}