/* You can add global styles to this file, and also import other style files */

@import '@ng-select/ng-select/themes/default.theme.css';
@import 'ngx-bootstrap/datepicker/bs-datepicker.css';
@import 'icheck-bootstrap/icheck-bootstrap.css';
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';
@import 'assets/scss/variables.scss';
/* Tourmie custom variables for Bootstrap 4 */
/*
@import '~ngx-sharebuttons/themes/default/default-theme';
@import '~ngx-sharebuttons/themes/material/material-dark-theme';
@import '~ngx-sharebuttons/themes/modern/modern-light-theme';
@import '~ngx-sharebuttons/themes/circles/circles-dark-theme';
*/

// $colors: (
//   tourmie-secondary: $tourmie-secondary,
//   tourmie-lightblue: $tourmie-lightblue,
//   tourmie-darkblue: $tourmie-darkblue,
//   tourmie-darkblue-15: $tourmie-darkblue-15,
//   tourmie-darkblue-20: $tourmie-darkblue-20,
//   tourmie-darkblue-25: $tourmie-darkblue-25,
//   tourmie-darkblue-30: $tourmie-darkblue-30,
//   tourmie-primary: $tourmie-primary,
//   tourmie-primary-90: $tourmie-primary-90,
//   tourmie-primary-85: $tourmie-primary-85,
//   tourmie-primary-80: $tourmie-primary-80,
//   tourmie-primary-75: $tourmie-primary-75,
//   tourmie-primary-70: $tourmie-primary-70,
//   tourmie-primary-65: $tourmie-primary-65,
//   tourmie-primary-60: $tourmie-primary-60,
//   tourmie-primary-55: $tourmie-primary-55,
//   tourmie-primary-50: $tourmie-primary-50,
//   tourmie-darkblue-2: $tourmie-darkblue-2
// );

// $theme-colors: (
//   tourmie-secondary: $tourmie-secondary,
//   tourmie-lightblue: $tourmie-lightblue,
//   tourmie-darkblue: $tourmie-darkblue,
//   tourmie-darkblue-15: $tourmie-darkblue-15,
//   tourmie-darkblue-20: $tourmie-darkblue-20,
//   tourmie-darkblue-25: $tourmie-darkblue-25,
//   tourmie-darkblue-30: $tourmie-darkblue-30,
//   tourmie-primary: $tourmie-primary,
//   tourmie-primary-90: $tourmie-primary-90,
//   tourmie-primary-85: $tourmie-primary-85,
//   tourmie-primary-80: $tourmie-primary-80,
//   tourmie-primary-75: $tourmie-primary-75,
//   tourmie-primary-70: $tourmie-primary-70,
//   tourmie-primary-65: $tourmie-primary-65,
//   tourmie-primary-60: $tourmie-primary-60,
//   tourmie-primary-55: $tourmie-primary-55,
//   tourmie-primary-50: $tourmie-primary-50,
//   tourmie-darkblue-2: $tourmie-darkblue-2
// );

@import 'bootstrap';
/* include bootstrap to recompile the Bootstrap 4 elements for the new custom colors */
@import 'assets/scss/adminlte.scss';
/* AdminLTE 3 theme */
@import 'assets/scss/font-size.scss';
@import 'assets/scss/icon-size.scss';
@import 'assets/scss/layered-shadows.scss';
@import 'assets/scss/bootstrap-tour.scss';
@import 'intl-tel-input/build/css/intlTelInput.css';
@import 'bootstrap/scss/buttons';
/* include buttons to override the button colors for new custom colors */
@import 'assets/scss/colors.scss';
/* color fixes (some adminlte colors break with the addition of new custom colors in variables.scss) DO NOT MOVE IT FROM THIS LINE */
@import 'ngx-toastr/toastr-bs4-alert';
// @import 'ngx-sharebuttons/themes/default/default-theme';
@import 'ngx-sharebuttons/themes/default';
@import 'assets/scss/alerts.scss';
@import 'assets/scss/smart-scroll.scss';
@import 'assets/scss/timeline.scss';
@import 'assets/scss/calendar.scss';
@import 'assets/scss/modal-sidebar.scss';
@import 'assets/scss/ion-rangeslider.scss';
@import 'assets/scss/custom-animations.scss';
@import 'assets/scss/google-place-autocomplete.scss';
@import 'assets/scss/google-map.scss';
@import 'assets/scss/app-root-spinner.scss';
@import 'assets/scss/bootstrap-col-xxl.scss';

/*
@import 'assets/scss/navbar.scss';
@import 'assets/scss/footer.scss';
*/


:root {
  --tourmie-secondary: #27a9e0;
  --tourmie-lightblue: #d2edf9;
  --tourmie-darkblue: #051320;
  --tourmie-darkblue-15: #0a2742;
  --tourmie-darkblue-20: #0e3458;
  --tourmie-darkblue-25: #11416e;
  --tourmie-darkblue-30: #154e84;
  --tourmie-primary: #faaf40;
  --tourmie-primary-90: #feeacd;
  --tourmie-primary-85: #fde0b4;
  --tourmie-primary-80: #fcd69c;
  --tourmie-primary-75: #fccb83;
  --tourmie-primary-70: #fbc16a;
  --tourmie-primary-65: #fbb751;
  --tourmie-primary-60: #faac38;
  --tourmie-primary-55: #f9a21f;
  --tourmie-primary-50: #f99806;
  --tourmie-dark-blue-footer: #051320;
  --tourmie-darkblue-2: #5f727f;
}


$app_colors_var: (
  tourmie-primary: var(--tourmie-primary),
  tourmie-primary-text: var(--tourmie-primary-text),
  tourmie-primary-light-5: var(--tourmie-primary-light-5),
  tourmie-primary-light-10: var(--tourmie-primary-light-10),
  tourmie-primary-light-15: var(--tourmie-primary-light-15),
  tourmie-primary-light-20: var(--tourmie-primary-light-20),
  tourmie-primary-light-25: var(--tourmie-primary-light-25),
  tourmie-primary-light-30: var(--tourmie-primary-light-30),
  tourmie-primary-light-35: var(--tourmie-primary-light-35),
  tourmie-primary-light-40: var(--tourmie-primary-light-40),
  tourmie-primary-light-45: var(--tourmie-primary-light-45),
  tourmie-primary-light-50: var(--tourmie-primary-light-50),
  tourmie-primary-light-55: var(--tourmie-primary-light-55),
  tourmie-primary-light-60: var(--tourmie-primary-light-60),
  tourmie-primary-light-65: var(--tourmie-primary-light-65),
  tourmie-primary-light-70: var(--tourmie-primary-light-70),
  tourmie-primary-light-75: var(--tourmie-primary-light-75),
  tourmie-primary-light-80: var(--tourmie-primary-light-80),
  tourmie-primary-light-85: var(--tourmie-primary-light-85),
  tourmie-primary-light-90: var(--tourmie-primary-light-90),
  tourmie-primary-light-95: var(--tourmie-primary-light-95),
  tourmie-primary-light-100: var(--tourmie-primary-light-100),
  tourmie-primary-dark-5: var(--tourmie-primary-dark-5),
  tourmie-primary-dark-10: var(--tourmie-primary-dark-10),
  tourmie-primary-dark-15: var(--tourmie-primary-dark-15),
  tourmie-primary-dark-20: var(--tourmie-primary-dark-20),
  tourmie-primary-dark-25: var(--tourmie-primary-dark-25),
  tourmie-primary-dark-30: var(--tourmie-primary-dark-30),
  tourmie-primary-dark-35: var(--tourmie-primary-dark-35),
  tourmie-primary-dark-40: var(--tourmie-primary-dark-40),
  tourmie-primary-dark-45: var(--tourmie-primary-dark-45),
  tourmie-primary-dark-50: var(--tourmie-primary-dark-50),
  tourmie-primary-dark-55: var(--tourmie-primary-dark-55),
  tourmie-primary-dark-60: var(--tourmie-primary-dark-60),
  tourmie-primary-dark-65: var(--tourmie-primary-dark-65),
  tourmie-primary-dark-70: var(--tourmie-primary-dark-70),
  tourmie-primary-dark-75: var(--tourmie-primary-dark-75),
  tourmie-primary-dark-80: var(--tourmie-primary-dark-80),
  tourmie-primary-dark-85: var(--tourmie-primary-dark-85),
  tourmie-primary-dark-90: var(--tourmie-primary-dark-90),
  tourmie-primary-dark-95: var(--tourmie-primary-dark-95),
  tourmie-primary-dark-100: var(--tourmie-primary-dark-100),

  tourmie-secondary: var(--tourmie-secondary),
  tourmie-secondary-text: var(--tourmie-secondary-text),
  tourmie-secondary-light-5: var(--tourmie-secondary-light-5),
  tourmie-secondary-light-10: var(--tourmie-secondary-light-10),
  tourmie-secondary-light-15: var(--tourmie-secondary-light-15),
  tourmie-secondary-light-20: var(--tourmie-secondary-light-20),
  tourmie-secondary-light-25: var(--tourmie-secondary-light-25),
  tourmie-secondary-light-30: var(--tourmie-secondary-light-30),
  tourmie-secondary-light-35: var(--tourmie-secondary-light-35),
  tourmie-secondary-light-40: var(--tourmie-secondary-light-40),
  tourmie-secondary-light-45: var(--tourmie-secondary-light-45),
  tourmie-secondary-light-50: var(--tourmie-secondary-light-50),
  tourmie-secondary-light-55: var(--tourmie-secondary-light-55),
  tourmie-secondary-light-60: var(--tourmie-secondary-light-60),
  tourmie-secondary-light-65: var(--tourmie-secondary-light-65),
  tourmie-secondary-light-70: var(--tourmie-secondary-light-70),
  tourmie-secondary-light-75: var(--tourmie-secondary-light-75),
  tourmie-secondary-light-80: var(--tourmie-secondary-light-80),
  tourmie-secondary-light-85: var(--tourmie-secondary-light-85),
  tourmie-secondary-light-90: var(--tourmie-secondary-light-90),
  tourmie-secondary-light-95: var(--tourmie-secondary-light-95),
  tourmie-secondary-light-100: var(--tourmie-secondary-light-100),
  tourmie-secondary-dark-5: var(--tourmie-secondary-dark-5),
  tourmie-secondary-dark-10: var(--tourmie-secondary-dark-10),
  tourmie-secondary-dark-15: var(--tourmie-secondary-dark-15),
  tourmie-secondary-dark-20: var(--tourmie-secondary-dark-20),
  tourmie-secondary-dark-25: var(--tourmie-secondary-dark-25),
  tourmie-secondary-dark-30: var(--tourmie-secondary-dark-30),
  tourmie-secondary-dark-35: var(--tourmie-secondary-dark-35),
  tourmie-secondary-dark-40: var(--tourmie-secondary-dark-40),
  tourmie-secondary-dark-45: var(--tourmie-secondary-dark-45),
  tourmie-secondary-dark-50: var(--tourmie-secondary-dark-50),
  tourmie-secondary-dark-55: var(--tourmie-secondary-dark-55),
  tourmie-secondary-dark-60: var(--tourmie-secondary-dark-60),
  tourmie-secondary-dark-65: var(--tourmie-secondary-dark-65),
  tourmie-secondary-dark-70: var(--tourmie-secondary-dark-70),
  tourmie-secondary-dark-75: var(--tourmie-secondary-dark-75),
  tourmie-secondary-dark-80: var(--tourmie-secondary-dark-80),
  tourmie-secondary-dark-85: var(--tourmie-secondary-dark-85),
  tourmie-secondary-dark-90: var(--tourmie-secondary-dark-90),
  tourmie-secondary-dark-95: var(--tourmie-secondary-dark-95),
  tourmie-secondary-dark-100: var(--tourmie-secondary-dark-100),

  tourmie-lightblue: var(--tourmie-lightblue),
  tourmie-darkblue: var(--tourmie-darkblue),
  tourmie-darkblue-15: var(--tourmie-darkblue-15),
  tourmie-darkblue-20: var(--tourmie-darkblue-20),
  tourmie-darkblue-25: var(--tourmie-darkblue-25),
  tourmie-darkblue-30: var(--tourmie-darkblue-30),
  tourmie-darkblue-2: var(--tourmie-darkblue-2),
  // tourmie-primary-90: var(--tourmie-primary-90),
  // tourmie-primary-85: var(--tourmie-primary-85),
  // tourmie-primary-80: var(--tourmie-primary-80),
  // tourmie-primary-75: var(--tourmie-primary-75),
  // tourmie-primary-70: var(--tourmie-primary-70),
  // tourmie-primary-65: var(--tourmie-primary-65),
  // tourmie-primary-60: var(--tourmie-primary-60),
  // tourmie-primary-55: var(--tourmie-primary-55),
  // tourmie-primary-50: var(--tourmie-primary-50),
);


// TODO: add bootstrap classes with colors from backend
@each $color_name, $color_value in $app_colors_var {

  // text-success | text-tourmie-primary --> DONE
  // btn-primary | btn-tourmie-primary --> DONE
  // btn-outline-primary | btn-outline-tourmie-primary --> DONE
  // bg-danger | bg-tourmie-primary --> DONE
  // border-tourmie-primary --> DONE
  // card-tourmie-primary --> DONE
  // alert-tourmie-primary --> DONE
  // icheck-tourmie-primary

  .text-#{$color_name} {
    color: $color_value;
  }

  .btn-#{$color_name} {

    background-color: $color_value;
    border-color: $color_value;

    @if (str-index($color_name, '-primary')) {
      color: var(--tourmie-primary-text);

      &:hover {
        background-color: var(--tourmie-primary-dark-5);
        border-color: var(--tourmie-primary-dark-5);
        color: var(--tourmie-primary-text);
      }

    }

    @else {

      color: var(--tourmie-secondary-text);

      &:hover {
        background-color: var(--tourmie-secondary-dark-5);
        border-color: var(--tourmie-secondary-dark-5);
        color: var(--tourmie-secondary-text);
      }

    }

  }



  // outline buttons
  .btn-outline-#{$color_name} {
    border-color: $color_value;
    color: $color_value;
  }

  .bg-#{$color_name} {
    background-color: $color_value;
  }


  // border
  .border-#{$color_name} {
    border-color: $color_value;
  }


  // card
  .card-#{$color_name} {
    border-top-color: $color_value;
  }


  // alert
  .alert-#{$color_name} {
    background-color: $color_value;
  }


  // icheck
  .icheck-#{$color_name}>input:first-child:not(:checked):not(:disabled):hover+label::before,
  .icheck-#{$color_name}>input:first-child:not(:checked):not(:disabled):hover+input[type="hidden"]+label::before {
    border-color: $color_value;
  }

  .icheck-#{$color_name}>input:first-child:not(:checked):not(:disabled):focus+label::before,
  .icheck-#{$color_name}>input:first-child:not(:checked):not(:disabled):focus+input[type="hidden"]+label::before {
    border-color: $color_value;
  }

  .icheck-#{$color_name}>input:first-child:checked+label::before,
  .icheck-#{$color_name}>input:first-child:checked+input[type="hidden"]+label::before {
    background-color: $color_value;
    border-color: $color_value;
  }

}








body {
  font-family: 'Open Sans', sans-serif;
  background: #f9f9f9;
}


.content-wrapper {
  background: #f9f9f9;
}

.login-page,
.register-page {
  height: 100% !important;
  background: #fff !important;
}

div.mobile-phone-container div.iti {
  width: 100%;
}



/* >>>>>>> language switch component <<<<<<<< */
.language-switch-select .ng-select-container {
  border: none !important;
  box-shadow: none;
}

#language .ng-select-container {
  background-color: transparent !important;
  box-shadow: none;
}

#language .ng-select-container:hover {
  box-shadow: none !important;
}

/* >>>>>>> language switch component <<<<<<<< */



/* fullscreen modal */
.modal-fs {
  margin: 0;
}

.modal-fs .modal-content {
  border-radius: 0px;
  box-shadow: none;
  min-height: 100vh;
  min-width: 100vw;
}




.font-weight-300 {
  font-weight: 300 !important;
}

.font-weight-500 {
  font-weight: 500 !important;
}

.font-weight-600 {
  font-weight: 600 !important;
}


/* Navbar padding */
.nav-padding-60 {
  padding-top: 60px;
}






.min-vh-25 {
  min-height: 25vh !important;
}

.min-vh-50 {
  min-height: 50vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.max-w-100 {
  max-width: 100% !important;
}

.vh-50 {
  height: 50vh !important;
}

.vh-75 {
  height: 75vh !important;
}

.vh-90 {
  height: 90vh !important;
}

.vh-100 {
  height: 100vh !important;
}

.height-auto {
  height: auto !important;
}



span.bs-rating-star,
span.bs-rating-star.active {
  color: var(--tourmie-primary) !important;
}



.pos-rel {
  position: relative;
}

.pos-abs {
  position: absolute;
}

.pos-fix {
  position: fixed;
}



div.modal-header {
  padding: 0.60rem 1rem;

  .modal-title {
    font-weight: normal;
  }

  .close {
    text-shadow: none;
  }

  .close:hover {
    cursor: pointer;
  }

}


img.stripe-secure-payments-blue {
  max-width: 180px;
}

label:not(.form-check-label):not(.custom-file-label) {
  font-weight: 600;
}


.btn-group.btn-group-user>.btn:not(:last-child):not(.dropdown-toggle),
.btn-group.btn-group-user>.btn-group:not(:last-child)>.btn {
  border-radius: 2rem !important;
}


.layout-top-nav .wrapper .main-header .brand-image {
  margin-top: 4px !important;
  height: 38px;
  /* 36px; */
}

img.img-radius {
  border-radius: 4px;
}

img.img-radius-24 {
  border-radius: 24px;
}


#tidio-chat {
  display: none;
}


ngx-intl-tel-input>div.iti {
  width: 100%;
}



.rounded-top-left {
  border-top-left-radius: 0.25rem;
}

.rounded-top-right {
  border-top-right-radius: 0.25rem;
}

.rounded-bottom-left {
  border-bottom-left-radius: 0.25rem;
}

.rounded-bottom-right {
  border-bottom-right-radius: 0.25rem;
}


/* Datepicker */
.theme-tourmie .bs-datepicker-head {
  color: var(--tourmie-primary) !important;
}

td.ng-star-inserted span.disabled.ng-star-inserted {
  background:
    linear-gradient(to top left,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0) calc(50% - 0.8px),
      rgba(0, 0, 0, 1) 50%,
      rgba(0, 0, 0, 0) calc(50% + 0.8px),
      rgba(0, 0, 0, 0) 100%)
}

/* ionrangeslider */
.irs--sharp .irs-handle {
  background-color: var(--tourmie-primary) !important;
}

.irs--sharp .irs-handle>i:first-child {
  border-top-color: var(--tourmie-primary) !important;
}

.irs--sharp .irs-bar {
  background-color: var(--tourmie-primary) !important;
}

.irs--sharp .irs-min,
.irs--sharp .irs-max {
  background-color: var(--tourmie-primary) !important;
}

.irs--sharp .irs-from,
.irs--sharp .irs-to,
.irs--sharp .irs-single {
  background-color: var(--tourmie-primary) !important;
}

.irs--sharp .irs-from::before,
.irs--sharp .irs-to::before,
.irs--sharp .irs-single::before {
  border-top-color: var(--tourmie-primary) !important;
}



/* Properties filters sidebar modal (desktop) */
.property-list-filters-desktop-modal .modal-content {
  border-radius: unset !important;
}


.cursor-pointer {
  cursor: pointer;
}



.line-clamp-1 {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  overflow: hidden;
  -webkit-box-orient: vertical;
}

.line-clamp-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  overflow: hidden;
  -webkit-box-orient: vertical;
}



ng-select#language:hover {
  div.ng-input {
    cursor: pointer;
  }
}


#filter-bar {
  top: 60px;
  z-index: 5;
}

.properties-list-container {
  padding-top: 60px;
}

.property-details-container {
  padding-top: 60px;
  /* FIXME: 60 when beta bar is removed */
  /* 21px beta bar +1 rem padding-y */
  min-height: 100vh;
}

#acco-results {
  min-height: calc(100vh - 115px);
}

.gmap-container {
  /* FIXME: top 115px / height 100vh-115px when beta banner is removed */
  top: calc(115px);
  position: sticky !important;
  width: 100%;
  height: calc(100vh - 115px - 0.5rem);
}


#mobile-filter-bar {
  top: 58px;
  z-index: 5;
}

#mobile-results-container {
  padding-top: 64px;
  /* Not based on calculation */
}



body.notifications {

  #filter-bar {
    top: calc(60px + 21px + 1rem);
    /* Navbar related -> should be 60px if no notifaction on top of navbar */
    z-index: 5;

  }

  .properties-list-container {
    padding-top: calc(60px + 21px + 1rem);
    /* Navbar related */
    ;
  }

  .property-details-container {
    padding-top: calc(60px + 21px + 1rem);
    /* 21px beta bar +1 rem padding-y */
  }

  #acco-results {
    min-height: calc(100vh - 136px);
  }

  .gmap-container {
    /* FIXME: top 115px / height 100vh-115px when beta banner is removed */
    top: calc(136px + 0.5rem);
    position: sticky !important;
    width: 100%;
    height: calc(100vh - 136px - 0.5rem);
    /*
      60px = navbar
      55px = filterbar
      21 = beta bar
      */
  }

  #mobile-filter-bar {
    top: calc(58px + 21px + 1rem);
  }

  #mobile-results-container {
    padding-top: 100px;
  }

}

.circle-white-icon {
  img {
    filter: brightness(0) invert(1) !important;
  }
}



// ngx-spinner > div.ngx-spinner-overlay{background-color: transparent !important;}
ngx-spinner>div.ngx-spinner-overlay {
  background-color: rgba(40, 40, 40, 0.1) !important;
}



/* Media Queries */

@media (min-width: 768px) and (max-width: 991.98px) {

  #mobile-filter-bar {
    top: 58px;
  }

  #mobile-results-container {
    padding-top: 64px;
  }

  body.notifications {

    #mobile-filter-bar {
      top: calc(84px + 0.5rem);
    }

    #mobile-results-container {
      padding-top: 100px;
    }

  }

}


@media (max-width: 991.98px) {

  li.nav-lang ng-select#language .ng-select-container>div.ng-value-container {
    /* language switch component */
    padding-left: 0px !important;
  }

  div.property-details-container {

    button.sb-wrapper.sb-show-icon {
      font-size: 12px !important;
    }

  }

}


@media (min-width: 992px) {}