.pac-container {
    z-index: 1100 !important;
}

.pac-item{
    z-index: 1105 !important;
    padding: 6px 10px !important;
    font-family: 'Open Sans', sans-serif !important;
}

.pac-logo:after{
    display: none !important;
}

.pac-icon{
    display: none !important;
}


