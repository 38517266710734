/*
.ion-range-slider-yellow .irs-bar {
    background-color: yellow !important;
}
.ion-range-slider-yellow .irs-line {
    background-color: yellow !important;
}

Ion range slider - color: tourmie-secondary
*/

// TODO: fix me
// .ion-range-slider-tourmie-secondary .irs-handle>i:first-child {
//     background-color: darken(var(--tourmie-secondary), 10%) !important;
// }

.ion-range-slider-tourmie-secondary .irs-bar,
.ion-range-slider-tourmie-secondary .irs-bar-edge {
    background-color: var(--tourmie-secondary) !important;
}

.ion-range-slider-tourmie-secondary .irs-from,
.ion-range-slider-tourmie-secondary .irs-to,
.ion-range-slider-tourmie-secondary .irs-single {
    background-color: var(--tourmie-secondary) !important;
}

.ion-range-slider-tourmie-secondary .irs-from:before,
.ion-range-slider-tourmie-secondary .irs-to:before,
.ion-range-slider-tourmie-secondary .irs-single:before {
    border-top-color: var(--tourmie-secondary) !important;
}



/* Ion range slider - color: tourmie-primary */
// TODO: fix me
// .ion-range-slider-tourmie-primary .irs-handle>i:first-child {
//     background-color: darken(var(--tourmie-primary), 10%) !important;
// }

.ion-range-slider-tourmie-primary .irs-bar,
.ion-range-slider-tourmie-primary .irs-bar-edge {
    background-color: var(--tourmie-primary) !important;
}

.ion-range-slider-tourmie-primary .irs-from,
.ion-range-slider-tourmie-primary .irs-to,
.ion-range-slider-tourmie-primary .irs-single {
    background-color: var(--tourmie-primary) !important;
}

.ion-range-slider-tourmie-primary .irs-from:before,
.ion-range-slider-tourmie-primary .irs-to:before,
.ion-range-slider-tourmie-primary .irs-single:before {
    border-top-color: var(--tourmie-primary) !important;
}