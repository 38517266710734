.fade-in {
 animation: fadeIn ease 7s;
 -webkit-animation: fadeIn ease 7s;
 -moz-animation: fadeIn ease 7s;
 -o-animation: fadeIn ease 7s;
 -ms-animation: fadeIn ease 7s;
}@keyframes fadeIn {
    0% {
    opacity:0;
    }
    30% {
    opacity:1;
    }
    80% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@-moz-keyframes fadeIn {
    0% {
    opacity:0;
    }
    30% {
    opacity:1;
    }
    80% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@-webkit-keyframes fadeIn {
    0% {
    opacity:0;
    }
    30% {
    opacity:1;
    }
    80% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@-o-keyframes fadeIn {
    0% {
    opacity:0;
    }
    30% {
    opacity:1;
    }
    80% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@-ms-keyframes fadeIn {
    0% {
    opacity:0;
    }
    30% {
    opacity:1;
    }
    80% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}