@import '../scss/variables.scss';

.app-loader {
    position: relative;
    top: 40vh;
    z-index: 1;
    margin: 0 auto 0;
    border: 8px solid #f2f2f2;
    border-top: 8px solid var(--tourmie-primary);
    border-radius: 50%;
    width: 64px;
    height: 64px;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}