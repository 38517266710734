$sizes: 160;

@mixin icon-size-classes {
  @for $i from 1 through $sizes {
     $icon_size: $i;
    .icon-size-#{$i}  {
        width: $icon_size + px !important;
        height: $icon_size + px !important;
    }
  }
}
@include icon-size-classes;