/* Smart Scroll (hide/show navbar on scroll) */
.smart-scroll{
    position: fixed !important;
    top: 0 !important;
    right: 0 !important;
    left: 0 !important;
    z-index: 1030 !important;
}
.scrolled-down{
    transform:translateY(-100%) !important;
    transition: all 0.3s ease-in-out !important;
}
.scrolled-up{
    transform:translateY(0) !important;
    transition: all 0.5s ease-in-out !important;
}
/* End of Smart Scroll */