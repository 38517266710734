/* Fullcalendar styling */
full-calendar .fc-button-primary{
    color: #fff;
    background-color: #2c3e50 !important;
    border-color: #2c3e50 !important;
}

full-calendar .fc-button-primary:not(:disabled).fc-button-active,
full-calendar .fc-button-primary:not(:disabled):active{
    color: #fff !important;
    background-color: #1a252f !important;
    border-color: #151e27 !important;
}

full-calendar div.fc-view-container{
    border-left: 1px solid #ddd;
    border-right: 1px solid #ddd;
}

full-calendar .fc-toolbar,
full-calendar .fc-toolbar.fc-header-toolbar{
    padding-left: 0px !important;
    padding-right: 0px !important;
    padding-top: 0px !important;
}

full-calendar .fc-event-container:hover{
    cursor: pointer;
}

full-calendar .fc-header-toolbar div.fc-left button{
    padding: 4px !important;
    font-size: 15px !important;
}

full-calendar .fc-header-toolbar div.fc-left button.fc-today-button,
full-calendar .fc-header-toolbar div.fc-right button{
    font-size: 15px !important;
    padding: 4px 10px !important;
}
/* End of Fullcalendar styling */