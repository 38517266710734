/*******************************
* MODAL AS LEFT/RIGHT SIDEBAR
* Add "modal-left" or "modal-right" in modal parent div, after class="modal".
* Get free snippets on bootpen.com
*******************************/
.modal{

    .modal-left.modal-dialog,
    .modal-right.modal-dialog {
        position: fixed;
        margin: auto;
        width: 320px;
        height: 100%;
        -webkit-transform: translate3d(0%, 0, 0);
        -ms-transform: translate3d(0%, 0, 0);
        -o-transform: translate3d(0%, 0, 0);
        transform: translate3d(0%, 0, 0);
    }

    .modal-left .modal-content,
    .modal-right .modal-content {
        height: 100%;
        overflow-y: auto;
    }


    .modal-left .modal-body,
    .modal-right .modal-body {
        padding: 15px 15px 80px;
    }

    .modal-right{
        right: 0px;
    }
}


.modal.fade{

    /*Left*/
    .modal-left .modal-dialog{
        left: -320px;
        -webkit-transition: opacity 0.3s linear, left 0.3s ease-out;
        -moz-transition: opacity 0.3s linear, left 0.3s ease-out;
        -o-transition: opacity 0.3s linear, left 0.3s ease-out;
        transition: opacity 0.3s linear, left 0.3s ease-out;
    }


    /*Right*/
    .modal-right .modal-dialog {
        right: -320px;
        -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
        -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
        -o-transition: opacity 0.3s linear, right 0.3s ease-out;
        transition: opacity 0.3s linear, right 0.3s ease-out;
    }

}

.modal.fade.in {
    .modal-left .modal-dialog{
        left: 0;
    }

    .modal-right .modal-dialog {
        right: 0;
    }

}


@media (max-width: 991.98px){


}




    