/* Default */

.alert-primary {
    color: #004085;
    background-color: #cce5ff;
    border-color: #b8daff;
}

.alert-secondary {
    color: #383d41;
    background-color: #e2e3e5;
    border-color: #d6d8db;
}

.alert-success {
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb;
}

.alert-danger {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
}

.alert-warning {
    color: #856404;
    background-color: #fff3cd;
    border-color: #ffeeba;
}

.alert-info {
    color: #0c5460;
    background-color: #d1ecf1;
    border-color: #bee5eb;
}

.alert-light {
    color: #818182;
    background-color: #fefefe;
    border-color: #fdfdfe;
}

.alert-dark {
    color: #1b1e21;
    background-color: #d6d8d9;
    border-color: #c6c8ca;
}



/* Without Opacity (AdminLTE 3) */

.alert-warning.alert-no-opacity {
    color: #1F2D3D;
    background: #ffc107;
    border-color: #edb100;
}

.alert-success.alert-no-opacity {
    color: #ffffff;
    background: #28a745;
    border-color: #23923d;
}

.alert-danger.alert-no-opacity {
    color: #ffffff;
    background: #dc3545;
    border-color: #d32535;
}

.alert-info.alert-no-opacity {
    color: #ffffff;
    background: #17a2b8;
    border-color: #148ea1;
}

.alert-primary.alert-no-opacity {
    color: #ffffff;
    background: #007bff;
    border-color: #006fe6;
}

.alert-secondary.alert-no-opacity {
    color: #ffffff;
    background: #6c757d;
    border-color: #60686f;
}

.alert-light.alert-no-opacity {
    color: #1F2D3D;
    background: #f8f9fa;
    border-color: #e9ecef;
}

.alert-dark.alert-no-opacity {
    color: #ffffff;
    background: #343a40;
    border-color: #292d32;
}

.alert-tourmie-secondary.alert-no-opacity {
    color: #ffffff;
    background: var(--tourmie-secondary);
    // border-color: darken(var(--tourmie-secondary), 15%); // TODO: fix me
}

.alert-tourmie-primary.alert-no-opacity {
    color: #1F2D3D;
    background: var(--tourmie-primary);
    // border-color: darken(var(--tourmie-primary), 15%); // TODO: fix me
}